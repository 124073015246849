const Poll = {

    cookieCont: null,
    main : null,
    form : null,
    result : null,
    fields : null,
    triggers: null,
    closeButton: null,
    recaptcha : false,
    captchaField : null,
    captchaBox : null,
    button : null,
    userDidClose: false,
   
    init : function(){

        // Recaptcha configure
        Poll.captchaConfigure();

        // Elements
        Poll.main = document.querySelector( '#modal-newsletter' );
        Poll.form = Poll.main.querySelector( 'form' );
        Poll.button = Poll.form.querySelector( 'button[data-action="submit"]' );
        Poll.triggers = document.querySelectorAll( '[data-action="open-news-form"]' );
        Poll.closeButton = document.querySelector( '[data-action="close-news-form"]' );
        Poll.fields = Poll.form.querySelectorAll( 'input' );
        Poll.nome = Poll.form.querySelector( 'input[name="nome"]' );
        Poll.mail = Poll.form.querySelector( 'input[name="mail"]' );
        Poll.optin = Poll.form.querySelector( 'input[name="news"]' );
        Poll.termos = Poll.form.querySelector( 'input[name="termos-de-uso"]' );
        Poll.apoio = Poll.form.querySelector( 'input[name="apoiador"]' );
        
        // To finish
        Poll.message = $( 'div.message' )
        Poll.signup = $( 'div.signup' )

        // Manipulate
        Poll.button.setAttribute( 'type', 'button.submit' );

        // Bind actions
        Poll.main.addEventListener( 'click', event => {
            if( event.target.id === 'modal-newsletter-inner' || event.target.id === 'modal-newsletter' ){
                Poll.close();
            }
        })
        Poll.closeButton.addEventListener( 'click', event => {
            Poll.close();
        })
        Poll.nome.addEventListener( 'keyup', Poll.checkSubmit )
        Poll.nome.addEventListener( 'blur', () => { Poll.validateNameField( true ) })
        Poll.mail.addEventListener( 'keyup', Poll.checkSubmit )
        Poll.mail.addEventListener( 'blur', () => { Poll.validateMailField( true ) })
        Poll.optin.addEventListener( 'change', Poll.checkSubmit )
        Poll.optin.addEventListener( 'blur', () => { Poll.validateCheckboxField( 'optin', true ) })
        Poll.termos.addEventListener( 'change', Poll.checkSubmit )
        Poll.termos.addEventListener( 'blur', () => { Poll.validateCheckboxField( 'termos', true ) })
        Poll.form.addEventListener( 'submit', event => {
            event.preventDefault()
            Poll.bindSubmit()
        });
        Poll.button.addEventListener( 'click', event => {
            event.preventDefault()
            Poll.bindSubmit()
        });
        if( Poll.triggers.length ){
            Poll.triggers.forEach( button => {
                button.addEventListener( 'click', event => {
                    gtag('event', 'open_popup', {
                        source_interaction: button.dataset.location
                    })
                    Poll.open()
                })
            })
        }

        // Check voto
        Poll.checkSubmit()

    },

    // Show modal
    open : function(){
        Poll.main.classList.add( 'opened' )
    },
    
    // Hide modal
    close : function(){
        Poll.main.classList.remove( 'opened' )
    },

    // Bind form submit
    bindSubmit : async function(){
    
        Loader()

        gtag('event', 'attempt_register', { agree_support: ( Poll.apoio.checked === true ? true : false ) })
    
        grecaptcha.execute( Poll.recaptcha )
    
    },

    // Captcha callback
    captchaCallback : function( token ){

        Loader( 'close' );

        // Insert captcha token
        Poll.captchaField.value = token;
        
        // Form validation
        Poll.validate();
    
    },
    
    getValidation : function(){
        return {
            nome: Poll.validateNameField( false ),
            mail: Poll.validateMailField( false ),
            optin: Poll.validateCheckboxField( 'optin', false ),
            termos: Poll.validateCheckboxField( 'termos', false )
        }
    },

    // Validate name
    validateNameField : function( show ){

        var error = false
        if( Poll.nome.value === '' ){
            error = 'Digite seu nome completo'
        }
        else if( Poll.nome.value.split( ' ' ).length < 2 ){
            error = 'Digite seu sobrenome'
        }

        if( show === true ){
            if( error ){
                Poll.nome.classList.add( 'error' )
            }
            else {
                Poll.nome.classList.remove( 'error' )
            }
        }

        return error === false

    },

    // Validate e-mail
    validateMailField : function( show ){

        var error = false
        if( Poll.mail.value === '' ){
            error = 'Digite seu e-mail'
        }
        else if( !Poll.validateEmail( Poll.mail.value ) ){
            error = 'Digite um e-mail válido'
        }
        
        if( show === true ){
            if( error ){
                Poll.mail.classList.add( 'error' )
            }
            else {
                Poll.mail.classList.remove( 'error' )
            }
        }

        return error === false

    },

    // Validate e-mail
    validateCheckboxField : function( field, show ){

        var error = Poll[ field ].checked !== true;
        
        if( show === true ){
            if( error ){
                Poll[ field ].classList.add( 'error' )
            }
            else {
                Poll[ field ].classList.remove( 'error' )
            }
        }

        return error === false

    },

    checkSubmit : function(){

        const validation = Poll.getValidation()
        if( validation.nome !== true || validation.mail !== true || validation.optin !== true || validation.termos !== true ){
            Poll.button.classList.add( 'disabled' )
        }
        else {
            Poll.button.classList.remove( 'disabled' )
        }

    },

    validate : function(){

        const validation = Poll.getValidation()
        if( validation.nome === true && validation.mail === true && validation.optin === true && validation.termos === true ){
        
            Poll.validateToken()
        
        }
        else {

            // Show errors
            Poll.validateNameField( true )
            Poll.validateMailField( true )
            Poll.validateCheckboxField( 'optin', true )
            Poll.validateCheckboxField( 'termos', true )

        }
        
    },

    validateEmail : function( email ){
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },

    validateToken : async function(){

        Loader();

        $.ajax({
            url: "./submit-newsletter.php",
            type : 'POST',
            dataType : 'JSON',
            data: { token: Poll.captchaField.value },
            beforeSend: function( xhr ) {
                xhr.overrideMimeType( "text/plain; charset=utf-8" );
            }
        })
        .done( response => {
            if( response.status === 'success' ){
                setTimeout( Poll.sendForm, 50 )
            }
            else {
                console.info( "response" );
                console.log( response );
                alert('Erro ao validar captcha')
            }
        })
        .fail( ( jqXHR, textStatus, errorThrown ) => {
            console.info( "jqXHR" );
            console.log( jqXHR );
        })
        .always( () => {
            Loader( 'close' );
            console.log( "complete" );
        });

    },

    sendForm : async function(){

        Loader()

        const data = new FormData( Poll.form )

        // Checkboxes parse
        data.set( 'news', ( Poll.optin.checked === true ? 'true' : false ) )
        data.set( 'apoiador', ( Poll.apoio.checked === true ? 'true' : false ) )
        data.set( 'termos-de-uso', ( Poll.termos.checked === true ? 'true' : false ) )
        
        // Remove token
        data.delete( 'g-recaptcha-response' )
        data.delete( 'recaptcha' )

        // Query string        
        const queryString = new URLSearchParams( data ).toString()

        $.ajax({
            url: "https://idec.org.br/cadastro-lead/entrada-leads_site-externo.php",
            type : 'POST',
            dataType : 'JSON',
            data: queryString,
            beforeSend: function( xhr ) {
                xhr.overrideMimeType( "text/plain; charset=utf-8" );
            }
        })
        .done( response => {
            Poll.receiveResponse( response );            
        })
        .fail( ( jqXHR, textStatus, errorThrown ) => {

            console.info( "textStatus" );
            console.log( textStatus );
            console.info( "jqXHR" );
            console.log( jqXHR );

            Poll.receiveResponse({ status: 'success' })
            
        })
        .always( () => {
            Loader( 'close' );
            console.log( "complete" );
        });

    },

    receiveResponse : function( response ){

        var
            status,
            title,
            message
        ;
        if( response === 200 ){

            gtag('event', 'generate_lead', { success: true })

            status = 'success'
            title = 'Inscrição realizada com sucesso!'
            message = 'Você receberá por e-mail informações semanais sobre alimentação saudável!'
        
        }
        else {

            gtag('event', 'generate_lead', { success: false })

            status = 'error'
            title = 'Ops, algo deu errado'
            message = 'Tente enviar o formulário novamente'
            
        }

        Poll.message.addClass( status )
        Poll.message.find( 'h2' ).html( title )
        Poll.message.find( 'em' ).html( message )

        Poll.signup.fadeOut( 300, () => {
            Poll.message.fadeIn()
        })

    },
    
    captchaConfigure : function( data ){

        Poll.captchaField = document.querySelector( 'input#recaptcha' );
        Poll.captchaBox = document.querySelector( '#recaptcha-box' );
        
        if( Poll.captchaField ){
        
            const recaptchaKey = Poll.captchaField.dataset.recaptchaKey;

            grecaptcha.ready(function(){
        
                Poll.recaptcha = grecaptcha.render(
                    Poll.captchaBox, 
                    {
                        'sitekey' : recaptchaKey,
                        'size' : 'invisible',
                        'callback' : function( token ){
                            Poll.captchaCallback( token );
                        }
                    }
                );
        
            });
        
        }

    },

    isMobile : function() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    }

}

window.addEventListener( 'DOMContentLoaded', Poll.init );
