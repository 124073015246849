var 
	Navigation,
	_body,
	_window
;

(function($){

	Navigation = {

		$navs : null,
		$menu : null,
		$toggle : null,
		$header : false,
		timeout : false,
		$triggers : null,
		targets : {},
		totalTargets : 0,
		lastScollTop : 0,
		offseLimit : true,
		timeResize : false,
		timeClose : 1000,
		fixedScroll : 0,
		lockedScroll : false,

		components : [],

		init : function(){

			// Body
			if( _body === undefined ){
				_body = $( 'body' );
			} 

			// Window
			if( _window === undefined ){
				_window = $( window );
			}

			// Elements
			this.$menu = $( '#menu' );
			this.$header = $( '#guia-lnd > header' );

			// Toggle
			this.$toggle = $( '[data-action="toggle-menu"]', this.$header );
			this.$toggle.on( 'click', function(e){
				e.preventDefault();
				Navigation.menuToggle();
			});

	    	// Check when resize
	    	$( window ).resize(function(){
	    		// Time resizing
	    		if( Navigation.timeResize ) clearTimeout( Navigation.timeResize );
	    		// Start timer
	    		Navigation.timeResize = setTimeout(function(){
	    			// Check items
					Navigation.checkItems();
	    		}, 100 );
	    	});
			Navigation.checkItems();

		},

		menuToggle : function(){
			if( this.$header.hasClass( 'menu-opened' ) ){
				this.menuClose();
			}
			else {
				this.menuOpen();
			}
		},

		menuOpen : function(){

			// Not stikie
			if( !Navigation.$header.hasClass( 'sticky' ) ){

				$( 'html, body' )
					.stop( true )
					.animate(
						{ scrollTop: 0 }, 
		        		200, 
		        		function(){
							Navigation.lockScroll();
							Navigation.$header.addClass( 'menu-opened' );
		        		}
		        	)
		        ;  

			}
			else {
				Navigation.lockScroll();
				Navigation.$header.addClass( 'menu-opened' );
			}

		},

		menuClose : function(){
			this.$header.removeClass( 'menu-opened' );
			Navigation.unlockScroll();
		},

		isLockedScroll : function(){
			return Navigation.lockedScroll === true;
		},

		lockScroll : function(){
			Navigation.lockedScroll = true;
			Navigation.fixedScroll = Navigation.getPageTop();
			$( 'html' ).addClass( 'locked-scroll' );
		},
		
		unlockScroll : function(){
			Navigation.lockedScroll = false;
			$( 'html' ).removeClass( 'locked-scroll' );
		},

		// Restore hash
		restoreHash : function( $el, scroll ){

			// Element ID backup
    		var elemnetID = $el.attr( 'id' );

            // Prevent default page scroll
            $el.attr( 'id', '' );
            // Restore hash
            window.location.hash = elemnetID;

            // Restore element ID
            $el.attr( 'id', elemnetID );

            // Scroll to element
            if( scroll === undefined || scroll !== false ){
				setTimeout(function(){
		        	Navigation.scrollTo( $el );
				}, 1 );
            }
		
		},

		checkItems : function(){

			// Navigations
			this.$navs = $( '[data-role="navigation"]' );

			// Clear components
			Navigation.components = [];
			
			// Targets
	    	if( this.$navs.length ){

	    		// Configura cada componente de navetação
	    		this.$navs.each(function(){
	    			Navigation.configureItem( this );
	    		});

	    		// Se existe algum componente de navetação
	    		if( Navigation.components.length ){
					Navigation.bind();
	    		}
	    		
	    	}
		
		},

		// Bind click
		clickMenuItem : function( data, item ){
			Navigation.menuClose();
		},

		closeMenu : function( data ){
			data.$.addClass( 'closed' );
		},

		openMenu : function( data ){

			data.$.removeClass( 'closed' );
		
		},

		// Configure navigation
		configureItem : function( item ){

			item.$ = item.$ || $( item );
			
			var data = item.$.data( 'Navigation' );
			if( data === undefined ){

				// Configure data
				data = {
					_ : item,
					$ : item.$,
					triggers : $( 'a', item.$ ),
					positions : [],
					targets : {},
					totalTargets : 0,
					indicator : $( '.indicator', item.$ ),
					timeHover : false,
					mHover : false
				};
				item.$.data( 'Navigation', data );

				// Show on hover
				data.$.hover(
					function(){

						// Clear timeout close
						if( data.timeHover ) clearTimeout( data.timeHover );

						// Keep status
						data.mHover = true;

						// Open menu
						Navigation.openMenu( data );
					
					},
					function(){

						// Keep status
						data.mHover = false;

						// Clear timeout close
						if( data.timeHover ) clearTimeout( data.timeHover );
						
						// Create timeout to close
						data.timeHover = setTimeout(function(){
							Navigation.closeMenu( data );
						}, Navigation.timeClose );
					
					}
				
				);

			}

			// If theres is triggers, check items and calculating positions
    		if( data.triggers.length ){
			
				// Trigger click
				data.triggers.off( 'click' ).on( 'click', function(){
					this.$ = this.$ || $( this );
					Navigation.clickMenuItem( data, this );
				});

				// Clearing
				data.targets = [];
				data.positions = [];

				data.triggers.each(function(){
					
					this.$ = this.$ || $( this );

					// There is no anchor
					if( this.href.indexOf( '#' ) >= 0 ){

						// Just anchor
						if( this.href.indexOf( '#' ) === 0 ){
							_id = url;
						}
						// Anchor with link
						else {
							_id = '#' + this.href.split( '#' ).pop();
						}

						// Target
						var $target = ( _id === '#' ) ? _body : $( _id );
						if( $target.length ){

							// Get top position
							var top_position = parseInt( $target.offset().top );
							if( top_position < 0 ) top_position = 0;

							// Insert in targets array
							if( !data.positions.includes( top_position ) ){
								data.positions.push( top_position );
							}

							// Insert in targets array
							var key_atgts = 'tgts-' + top_position;
							if( data.targets[ key_atgts ] === undefined ){
								data.targets[ key_atgts ] = [];
							}
							data.targets[ key_atgts ].push( $target );

							// Increment total
							data.totalTargets++;

						}

					}

		            // Bind scroll
		    		if( data.totalTargets > 0 ){

		    			// Ordering positions
		    			data.positions = data.positions.sort(function( a, b ){ return a - b });

		    			// Add component
		    			Navigation.components.push( data );	    		
		    		
		    		}

				});

			}

		},

	    bind : function(){

	    	// Binding scroll
            Navigation.checkScroll( true );
            _window.bind( 'scroll', function(){
                Navigation.checkScroll();
            });

            // Not in safari
			if( Navigation.isSafari() ) return true;

	        // Bind clicks
	        $( 'body' ).off( 'click' ).on( 'click', 'a', function( e ){

	            this.$ = this.$ || $( this );

	            // Set piki sanfona free
	            if( this.$.parents( '.piki-sanfona' ).length ) return true;

	            // There is no href
	            if( this.href === undefined || this.href.indexOf( '#' ) < 0 ) return true;
	            
	            // There is no destiny
	            if( this.href === '#' ){
	                event.preventDefault();
	                return;
	            }

	            // Element ID
	            elemnetID = this.href.split( '#' ).pop();

	            // Links
	            if( elemnetID.indexOf( 'http' ) === 0 ){
	                return true;
	            }

	            // Actual url
	            var $target = $( '#' + elemnetID );
	            if( $target.length ){
	                event.preventDefault();
	                Navigation.restoreHash( $target );
	            }

	        });

	    },

	    vWidth : function vWidth(){
	        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	    },

	    vHeight : function vHeight(){
	        return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	    },

	    getPageTop : function(){
	    	var top = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 );
	    	// if( this.$header.length ){
	    	// 	top += this.$header.outerHeight();
	    	// }
	    	return top;
	    },

		getPageHeight : function(){
		    var doc = document;
		    return  Math.max(
		        doc.body.scrollHeight, doc.documentElement.scrollHeight,
		        doc.body.offsetHeight, doc.documentElement.offsetHeight,
		        doc.body.clientHeight, doc.documentElement.clientHeight
		    );
		},

		getScrollInfo : function(){

			var 
				pageHeight = this.getPageHeight(),
				pageTop = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 ),
				viweportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
				viweportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
				realPercuse = Math.ceil( pageTop + viweportHeight )
			;

			return {
				pageHeight : pageHeight,
				pageTop : pageTop,
				viweportHeight : viweportHeight,
				realPercuse : realPercuse,
				viweportWidth : viweportWidth
			};
		
		},

		checkIfBottom : function(){

			var scrlInfo = Navigation.getScrollInfo();
			return scrlInfo.realPercuse >= scrlInfo.pageHeight;

		},

		isSafari : function(){
			var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
			return isSafari;
		},

	    checkScroll : function( initing ){

	    	// Locked scroll
			if( Navigation.lockedScroll ){
				window.scrollTo( 0, Navigation.fixedScroll );
			}

	    	// Scroll info
			var scrlInfo = Navigation.getScrollInfo();
			var $limitElement = $( 'section.utilizar-gratuitamente' );

	    	// Top page positioun
	    	var scrollTop = Navigation.getPageTop();

	    	// Initial class
	    	if( scrollTop === 0 ){
	    		$( 'html' ).addClass( 'init-scroll' );
	    	}
	    	else {
	    		$( 'html' ).removeClass( 'init-scroll' );
	    	}

	    	// Direction
            var dir = scrollTop >= Navigation.lastScrolTop ? 'down' : 'up';

            if( scrollTop >= 29 ){
            	this.$header.addClass( 'sticky' );
            }
            else {
            	this.$header.removeClass( 'sticky' );
            }

	    	// Analisys each component
	    	this.components.forEach(function( data ){

	    		if( !data.mHover && initing === undefined ){

	    			// Clear timeout close
					if( data.timeHover ) clearTimeout( data.timeHover );

	    			// Closing menu
		    	 	Navigation.closeMenu( data );
	    		
	    		}

				// Start calculate
		    	var 
		    		start = data.positions[ 0 ],
		    		items_key = false
		    	;

		    	// At top
		    	if( scrollTop <= start ){

		    		items_key = start;
		    	
		    	}
		    	// At bottom
		    	else if( Navigation.checkIfBottom() ){
				
					items_key = data.positions[ data.positions.length - 1 ];
		    	
		    	}
		    	// Calculate positions
		    	else {

		    		// Offset
		    		var top_compare = scrollTop;

		    		// Section scroll offset
		    		var _offset_css = $( '#guia-lnd section' ).first().css( 'scroll-margin-top' );
		    		_offset_css = _offset_css === undefined ? 0 : parseInt( _offset_css );
		    		
		    		if( Navigation.offseLimit ){
			    		
		    			// Scroll margin offset
			    		if( _offset_css > 0 && start > 0 ){
			    			top_compare -= _offset_css;
			    		}

		    			// Others browsers
		    			if( !Navigation.isSafari() ){

			    			var _offset = Math.max( document.documentElement.clientHeight || 0, window.innerHeight || 0 ) / 4;

			    			if( dir === 'down' ){
			    				top_compare += _offset;
			    			}
			    			else {
			    				top_compare += _offset * 2;
			    			}

		    			}
		    			else {

							top_compare += _offset_css;

		    			}
		    		
		    		}
		    	
		    		// Calculate
		    		data.positions.forEach(function( position ){

			    		if( top_compare >= ( parseInt( position ) - start ) ){			    	
			    		   	items_key = position;
			    		}
		    		
		    		});
		    	
		    	}

		    	// Unmark triggeres
		    	data.triggers.removeClass( 'active' );

		    	// Marking classes
		    	var targets_keys = Object.keys( data.targets );

				// Walk trought arrays items
		    	targets_keys.forEach(function( target ){
		    		var elements = data.targets[ target ];
		    		elements.forEach(function( item ){
		    			item.removeClass( 'active' );
		    		});
		    	});

		    	// Mark items
		    	var key_atgts = 'tgts-' + items_key;
		    	if( data.targets[ key_atgts ] !== undefined ){

		    		// Add class to target
					data.targets[ key_atgts ].forEach(function( target ){
			    		
			    		// Add class to element
			    		target.addClass( 'active' );
			    		
			    		// Add class to triggers
			    		var _triggers = data.triggers.filter( '[href="#'+ target.attr( 'id' ) +'"]' );
			    		if( _triggers.length ){

				    		_triggers.addClass( 'active' );

				    		if( data.indicator.length ){

				    			var _first_trigger = _triggers.first();
				    			var _befores = _first_trigger.prevAll();
				    			var _new_top = parseInt( _first_trigger.css( 'margin-top' ) );

				    			_befores.each(function(){
				    				this.$ = this.$ || $( this );
				    				_new_top += this.$.outerHeight( true );
				    			});

				    			data.indicator.css( 'top', _new_top );
				    			
				    		}

			    		}
		    	
		    		});
		    	
		    	}
	    		
	    	});

			Navigation.lastScrolTop = scrollTop;

	    },

	    scrollTo : function( selector, callback ){

	        var $target = typeof( selector ) === 'object' ? selector : $( selector );            
	        if( !$target.length ) return;

			var newY;
	        if( $target.attr( 'id' ) === 'inicio' ){
	        	newY = 0;
	        }
	        else {
		        newY = $target.offset().top;
	        }

	        var actualY = _window.scrollTop();
	        var distance = actualY - newY;

	        if( distance < 0 ) distance *= -1;
	        
	        var time = ( 600 - ( distance * 0.1 ) );
	        if( time < 600 ) time = 600;
	        
	        var offset = $target.data( 'offset' );
	        if( offset ){

	            try {

	                var 
	                    _json = JSON.parse( offset.split( "'" ).join( '"' ) )
	                    _offset = false,
	                    _vWidth = Navigation.vWidth()
	                ;

	                $.each( _json.breaks, function( _break ){
	                    if( _vWidth <= _break ){
	                        _offset = parseInt( this );
	                        return false;
	                    }
	                });

	                offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

	            }
	            catch( e ){

	                var _offset = parseInt( offset );
	                offset = _offset > 0 ? _offset : 0;
	            
	            }

	        }
	        else {

	        	var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	        	offset = vw >= 992 ? 62 : 55;
	        
	        }

	        window.scrollTo( 0,  (newY - offset ) );

        	if( callback !== undefined ){
        		callback();
        	}

	    }
		
	};

	$(function(){
		$( 'body' ).imagesLoaded(function(){
			Navigation.init();
		});
	});

})(jQuery);

