// Cookies
var Cookie = {

    set : function setCookie( name, value, days ){
        
        var expires, days, date;
        // Infinito
        if ( days === undefined ){
            days = 99999;
        }
        // Dura só durante a sessão do browser
        else if ( days === false ){
            days = '';
        }
        // Calcula o tempo de expiração do cookie
        if( days === '' ){
            expires = '';
        }
        else {
            date = new Date();
            date.setTime( date.getTime() + ( days * 24*60*60*1000 ) );
            expires = "; expires=" + date.toGMTString();
        }

        document.cookie = name + "=" + value + expires + "; path=/; SameSite=Strict"; // domain=" + domain
    
    },

    delete : function deleteCookie( name ){
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict";
    },

    get : function getCookie( name ){
        var name = name + "=";
        var ca = document.cookie.split( ';' );
        for( var i = 0; i < ca.length; i++ ){
            var c = ca[i];
            while( c.charAt(0) == ' ' ){
                c = c.substring(1);
            }
            if( c.indexOf( name ) == 0 ){
                return c.substring( name.length, c.length );
            }
        }
        return '';
    }

};